<template>
  <v-checkbox
      v-if="isCheckbox()"
      :label="label"
      :required="required"
      v-model="mutableValue"
  />

  <v-text-field
      v-else-if="isTextarea()"
      :label="label"
      :multi-line="true"
      v-model="mutableValue"
  />

  <v-radio-group v-else-if="isRadio()" :label="label" v-model="mutableValue">
    <v-radio v-for="value in values" :key="value.id" :label="value.name" :value="value.value"/>
  </v-radio-group>

  <v-autocomplete
      v-else-if="isSelect()"
      :label="label"
      :items="values"
      :item-text="'name'"
      :item-value="'value'"
      v-model="mutableValue"

  />

  <v-text-field
      v-else-if="isNumber()"
      :label="label"
      :type="'number'"
      :required="required"
      v-model="mutableValue"
  />

  <v-text-field
      v-else
      :label="label"
      :type="'text'"
      :required="required"
      v-model="mutableValue"
  />
</template>

<script>
export default {
  name: 'form-field',
  props: {
    label: {
      required: true,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
    values: {
      default: () => {
        return [];
      },
      required: false,
      type: Array,
    },
    value: {
      default: '',
      required: false,
    },
    required: {
      default: false,
      required: true,
      type: Boolean,
    },
  },
  watch: {
    mutableValue: {
      handler() {
        this.$emit('input',
            this.getMutableValue(),
        );
      },
      deep: true,
    },
    value: {
      handler() {
        this.mutableValue = this.value;
      },
      deep: true,
    },
  },
  data() {
    return {
      mutableValue: this.value,
    };
  },
  methods: {
    /**
     * Get the return value.
     *
     * @returns {*}
     */
    getMutableValue() {
      return this.mutableValue;
    },

    /**
     * Check the component's type against the given type.
     *
     * @param type
     * @returns {boolean}
     */
    isOfType(type) {
      return this.type === type;
    },

    /**
     * @see isOfType
     *
     * @returns {boolean}
     */
    isCheckbox() {
      return this.isOfType('checkbox');
    },

    /**
     * @see isOfType
     *
     * @returns {boolean}
     */
    isText() {
      return this.isOfType('text');
    },

    /**
     * @see isOfType
     *
     * @returns {boolean}
     */
    isTextarea() {
      return this.isOfType('textarea');
    },

    /**
     * @see isOfType
     *
     * @returns {boolean}
     */
    isNumber() {
      return this.isOfType('number');
    },

    /**
     * @see isOfType
     *
     * @returns {boolean}
     */
    isSelect() {
      return this.isOfType('select');
    },

    /**
     * @see isOfType
     *
     * @returns {boolean}
     */
    isRadio() {
      return this.isOfType('radio');
    },
  },
};
</script>
