<template>
  <v-container>
    <v-row class="mt-5" align="center" justify="center">
      <v-col cols="12" sm="10" md="8" lg="6" xl="4">
        <v-form ref="form">
          <v-subheader class="pa-0">Profiel</v-subheader>
          <v-card class="themed-color">
            <div class="text-center pa-5"
                 v-if="profileFormLoading">
              <v-progress-circular
                  :width="3"
                  color="accent"
                  indeterminate
              ></v-progress-circular>
            </div>
            <v-card-text v-else>
              <v-text-field
                  v-model="profileForm.firstname"
                  label="Voornaam"
                  hint="Hoe heet je?"
                  disabled
              ></v-text-field>
              <v-text-field
                  v-model="profileForm.insertion"
                  label="Tussenvoegsel"
                  disabled
              ></v-text-field>
              <v-text-field
                  v-model="profileForm.lastname"
                  label="Achternaam"
                  disabled
              ></v-text-field>
              <v-text-field
                  v-model="profileForm.email"
                  label="Email"
                  hint="Welk e-mail adres wil je gebruiken om in te loggen?"
                  disabled
              ></v-text-field>
              <div v-for="(field, index) in formFields" :key="index">
                <template v-if="formFields !== null">
                  <form-field
                      :label="field.label"
                      :type="field.type"
                      :required="field.is_required"
                      :values="field.values"
                      v-model="profileForm.model_form.form_fields[field.key]"
                  />
                </template>

              </div>
            </v-card-text>
            <v-btn
                color="accent"
                dark
                small
                absolute
                bottom
                right
                fab
                @click="updateProfile"
            >
              <v-icon>fa-save</v-icon>
            </v-btn>
          </v-card>

          <v-subheader class="pa-0">Wachtwoord</v-subheader>
          <v-card class="themed-color">
            <div class="text-center pa-5"
                 v-if="passwordFormLoading">
              <v-progress-circular
                  :width="3"
                  color="accent"
                  indeterminate
              ></v-progress-circular>
            </div>
            <v-card-text v-else>
              <v-text-field
                  v-model="passwordForm.password"
                  :append-icon="passwordShow ? 'fa-eye-slash' : 'fa-eye'"
                  :type="passwordShow ? 'text' : 'password'"
                  label="Wachtwoord"
                  hint="kies een veilig en lang wachtwoord"
                  @click:append="passwordShow = !passwordShow"
              ></v-text-field>
              <v-text-field
                  v-model="passwordForm.password_confirmation"
                  :append-icon="passwordShow ? 'fa-eye-slash' : 'fa-eye'"
                  :type="passwordShow ? 'text' : 'password'"
                  label="Wachtwoord ter controle"
                  hint="Hetzelfde wachtwoord"
                  @click:append="passwordShow = !passwordShow"
                  :rules="[(v) => v === passwordForm.password || 'Beiden wachtwoorden moeten hezelfde zijn']"
              ></v-text-field>
            </v-card-text>
            <v-btn
                color="accent"
                dark
                small
                absolute
                bottom
                right
                fab
                @click="updatePassword"
            >
              <v-icon>fa-save</v-icon>
            </v-btn>
          </v-card>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import FormField from '../components/FormField.vue';

export default {
  name: 'Profile',
  components: { FormField },
  data() {
    return {
      passwordForm: {
        password: '',
        password_confirmation: '',
      },
      profileForm: {
        firstname: '',
        insertion: '',
        lastname: '',
        email: '',
        model_form: {},
      },
      passwordFormLoading: false,
      profileFormLoading: false,
      passwordShow: false,
      formFields: [],
    };
  },
  methods: {
    serverError(name) {
      if (this.ipFormErrors !== null && typeof this.ipFormErrors[name] !== 'undefined') {
        return this.ipFormErrors[name][0];
      }
      return true;
    },
    updateProfile() {
      this.profileFormLoading = true;

      this.$http.put('me', this.profileForm).finally(() => {
        this.profileFormLoading = false;
      });
    },
    updatePassword() {
      this.passwordFormLoading = true;
      this.$http.put('me/password', this.passwordForm).finally(() => {
        this.passwordFormLoading = false;
      });
    },
    getProfile() {
      return new Promise((resolve) => {
        this.$http.get('me').then((response) => {
          resolve(response.data.data);
        });
      });
    },
    getFormFields() {
      let url = 'user/form-fields';
      return new Promise((resolve) => {
        this.$http.get(url)
            .then((response) => {
              resolve(response.data.data);
            });
      });
    },
  },
  created() {
    this.getProfile().then((profile) => {
      this.profileForm.email = profile.email;
      this.profileForm.firstname = profile.firstname;
      this.profileForm.insertion = profile.insertion;
      this.profileForm.lastname = profile.lastname;
      let formFields = profile.model_form.form_fields_with_data;
      this.profileForm.model_form = { form_fields: [] };
      let output = {};
      formFields.forEach(formField => {
        output[formField.key] = formField.value;
      });
      this.profileForm.model_form.form_fields = output;

      this.getFormFields().then((response) => {
        this.formFields = response;
      });
    });
  },
};
</script>

<style scoped>

</style>
